import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../../components/floating-button/floating-button';
import Layout from '../../../../components/layout';
import React from 'react';

const ReadyToWorkPage = (props) => {
    const components = props.data.cms.readyToWorkPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default ReadyToWorkPage;

export const query = graphql`
query ($locale: String!) {
  cms {
    readyToWorkPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          title
          textBoxCustomClasses
          headerSubtitle: subtitle
          primaryImage {
            url
          }
          mobileContentOffset
          contentOffset
          backgroundImageHeight
          backgroundImage {
            url
          }
        }
        ... on STRAPI_ComponentComponentsHorizontalScroll {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          horizontalScrollColor
          hasMobileGrayBackground
          imagesTailwindClasses
          HorizontalScrollBenefits {
            title
            subtitle
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          tabsDarkBackground
          tabHeight
          tabs {
            type
            title
            subtitle
            scrollColor
            link
            label
            imagesTitles
            imagesMetadata
            imagesTailwindClasses
            images {
              url
            }
            fullWidthImageItemsDirection
          }
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
        }
        ... on STRAPI_ComponentComponentsImagesWithOffset {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          offsetComponentType
          imagesWithOffsetScrollColor
          contentOffset
          content {
            title
            subtitle
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentUseCasesUseCasesSection {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          UseCasesColumns {
            title
            subtitle
            icon {
              url
            }
            backgroundImage {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsSingleImage {
          __typename
          topPusherHeightMobile
          topPusherHeight
          image {
            url
          }
          singleImageContent
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
      }
    }
  }
}
`;
